import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { Banner, CreateBannerDto } from '../../models/Banner';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient) {}

  createBanner(bannerDto: CreateBannerDto): Observable<void> {
    const url = endpoints.banners;

    return this.http.post<void>(url, bannerDto);
  }

  deleteBanner(bannerId: number): Observable<void> {
    const baseUrl = endpoints.banners;
    const url = `${baseUrl}/${bannerId}`;

    return this.http.delete<void>(url);
  }

  listBanners(idClient: string): Observable<Banner[]> {
    const url = `${endpoints.banners}?idClient=${idClient}`;

    return this.http.get<Banner[]>(url);
  }
}
